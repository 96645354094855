import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from "react-router-dom";

import { Fragment, lazy, Suspense, useEffect, useState } from "react";
import { GARouteChangeListener, initGA } from "../monitoring/googleAnalytics";

const LandingPage = lazy(() => import("pages/Landing/LandingPage"));
const LoginPage = lazy(() => import("pages/Landing/LoginPage"));
const OrderSuccessPage = lazy(() => import("feed/pages/OrderSuccessPage/OrderSuccessPage"));
const OrderErrorPage = lazy(() => import("feed/pages/OrderErrorPage/OrderErrorPage"));

const FeedRouter = lazy(() => import("../feed/router"));
const MerchantRouter = lazy(() => import("./merchantRouter"));

const router = createBrowserRouter(
  createRoutesFromElements(

    <Fragment>
      <Route path="/" element={
        <Suspense fallback={<Fragment />}>
          <LandingPage />
        </Suspense>
      } />

      <Route path="/login" element={
        <Suspense fallback={<Fragment />}>
          <LoginPage />
        </Suspense>
      } />

      {/* Merchant routes */}
      <Route path="/merchant/*" element={
        <Suspense fallback={<Fragment />}>
          <MerchantRouter />
        </Suspense>
      } />

      <Route path="/order/success" element={
        <Suspense fallback={<Fragment />}>
          <OrderSuccessPage />
        </Suspense>
      } />

      <Route path="/order/error" element={
        <Suspense fallback={<Fragment />}>
          <OrderErrorPage />
        </Suspense>
      } />

      {/* Feed routes */}
      <Route path="/*" element={
        <Suspense fallback={<Fragment />}>
          <FeedRouter />
        </Suspense>
      } />
    </Fragment>
  )
);

const App = () => {
  useEffect(() => {
    initGA();
  }, []);

  return (
    <>
      <GARouteChangeListener />
      <RouterProvider router={router} />
    </>
  );
};

export default App;
