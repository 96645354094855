export const getGATrackingId = (): string => {
  const gaId = process.env.REACT_APP_CUSTOMER_GA_TRACKING_ID;

  if (gaId === undefined) {
    console.warn('Internal feed GA tracking is disabled.');
    return "";
  }

  console.warn('Internal feed GA tracking will be enabled.')
  return String(gaId);
}