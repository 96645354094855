import ReactGA from 'react-ga4';
import { getGATrackingId } from "feed/integrations/analytics/ga/configuration";


const getInternalGeneralGATrackingId = (): string => {
  const gaId = process.env.REACT_APP_GA_TRACKING_ID;

  if (gaId === undefined) {
    console.warn('General GA tracking is disabled.');
    return "";
  }

  console.warn('General GA tracking is enabled.');
  return String(gaId);
}

const getMerchantGATrackingId = (): string => {
const gaId = process.env.REACT_APP_MERCHANT_GA_TRACKING_ID;

  if (gaId === undefined) {
    console.warn('Merchant GA tracking is disabled.');
    return "";
  }

  console.warn('Merchant GA tracking is enabled.');
  return String(gaId);
}

export const initGA = () => {
  const generalTrackingId = getInternalGeneralGATrackingId();
  const feedTrackingId = getGATrackingId();
  const merchantTrackingId = getMerchantGATrackingId();

  ReactGA.initialize([
    { trackingId: generalTrackingId },  // Internal Brandlink analytics
    { trackingId: feedTrackingId },     // Internal Feed analytics
    { trackingId: merchantTrackingId }, // Internal Merchant analytics
  ]);
};

export const logPageView = (pathname: string) => {
  ReactGA.send({ hitType: 'pageview', page: pathname });
};

export const logEvent = (category: string, action: string, label: string) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};