import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { logPageView } from "./googleAnalytics";

const GARouteChangeListener = () => {
  // const location = useLocation();

  useEffect(() => {
    logPageView(window.location.pathname);
  }, []);

  return null;
};

// import { useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import { logPageView } from "./googleAnalytics"; // Assuming this function logs a page view to GA
//
// const GARouteChangeListener = () => {
//   const location = useLocation(); // Get the current location
//
//   useEffect(() => {
//     // Log a page view every time the route changes
//     logPageView(location.pathname);
//   }, [location.pathname]); // Only re-run the effect if the path changes
//
//   return null;
// };
//
export default GARouteChangeListener;